import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import SearchIcon from "@material-ui/icons/Search";
import PhotoIcon from "@material-ui/icons/Photo";
import { css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { CheckBox } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";

import _ from "lodash";

const imgsButtonStyles = css`
  margin-top: 20px;
`;

const neededItemsHeaderStyles = css`
  margin-top: 20px;
`;

const neededItemsStyles = css`
  width: 400px;
`;

const imgContainerStyles = css`
  display: flex;
  flex-direction: horizontal;
`;

const imgStyles = css`
  margin: 10px;
  height: 100%;
  padding: 2px;
  width: 462px;
`;

const itemDropStyles = css`
  width: 1000px;
  margin: 20px;
`;

const formStyles = css`
  display: flex;
  width: 500px;
`;

const selectStyles = css`
  padding-right: 10px;
  width: 100%;
`;

const searchButtonStyles = css`
  background-color: #fff;
  height: 30px;
  width: 120px;
  padding: 20px;
`;

const ArmorSearch = () => {
  const [showArmorImages, setShowArmorImages] = useState(false);
  const [armorSets, setArmorSets] = useState([]);
  const [selectedArmorSet, setSelectedArmorSet] = useState(null);
  const [selectedParts, setSelectedParts] = useState([]);
  const [neededParts, setNeededParts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const resp = await fetch("/armorSets");
      let jsonBody = await resp.json();
      setArmorSets(jsonBody);
    }

    fetchData();
  }, []);

  const fetchArmorSets = async (e) => {
    e.preventDefault();

    let armorSet = armorSets.find(
      ({ id }) =>
        id === document.getElementById("armorSets_form").armor_set.value
    );
    setSelectedArmorSet(armorSet);
    setSelectedParts([]);
    setNeededParts([]);

    const armorParts = await Promise.all(
      armorSet.parts.map(async (partId) => {
        const partRaw = await fetch(
          `/armorParts/${encodeURIComponent(partId)}`
        );
        return await partRaw.json();
      })
    );

    setSelectedParts(armorParts);
    setNeededParts(armorParts.map(({ id }) => id));
  };

  const toggleNeededPart = (e) => {
    const [id] = e.target.name.split("_");

    console.log(id, e.target.checked);

    if (!e.target.checked) {
      setNeededParts(neededParts.filter((p) => p !== id));
    } else {
      setNeededParts([...neededParts, id]);
    }
  };

  console.log(neededParts, selectedParts);

  return (
    <div>
      <Typography variant="h4">Armor sets</Typography>
      <form css={formStyles} id="armorSets_form">
        <Select
          css={selectStyles}
          name="armor_set"
          options={armorSets.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
        />

        <Button
          variant="contained"
          css={searchButtonStyles}
          onClick={fetchArmorSets}
        >
          <SearchIcon />
          Search
        </Button>
      </form>

      {selectedArmorSet && (
        <Fragment>
          <Button
            css={imgsButtonStyles}
            variant="contained"
            onClick={() => {
              setShowArmorImages(!showArmorImages);
            }}
          >
            <PhotoIcon />
            See how it looks
          </Button>
          {showArmorImages && (
            <div css={imgContainerStyles}>
              <Paper elevation={3} css={imgStyles}>
                <img src={selectedArmorSet.maleImgs} />
              </Paper>
              <Paper elevation={3} css={imgStyles}>
                <img src={selectedArmorSet.femaleImgs} />
              </Paper>
            </div>
          )}

          <Typography variant="h5" css={neededItemsHeaderStyles}>
            Parts in armor set:
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Need</TableCell>
                  <TableCell>Part name</TableCell>
                  <TableCell>Materials</TableCell>
                  <TableCell align="right">Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedParts.map(({ id, name, materials, cost }) => (
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={neededParts.includes(id)}
                        name={id + "_checkbox"}
                        onChange={toggleNeededPart}
                      />
                    </TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      {materials.map((mat) => (
                        <Fragment>
                          {mat.quantity + " " + mat.item.name}
                          <br />
                        </Fragment>
                      ))}
                    </TableCell>
                    <TableCell align="right">{cost}z</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h5" css={neededItemsHeaderStyles}>
            Total materials needed:
          </Typography>
          <TableContainer component={Paper} css={neededItemsStyles}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Material name</TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(
                  neededParts
                    .map((partId) =>
                      selectedParts.find(({ id }) => id === partId)
                    )
                    .reduce((acc, part) => {
                      return _.mergeWith(
                        acc,
                        part.materials.reduce(
                          (acc2, m) => ({
                            ...acc2,
                            [m.item.name]: m.quantity,
                          }),
                          {}
                        ),
                        (a, b) => {
                          console.log(a, b);
                          return (a || 0) + (b || 0);
                        }
                      );
                    }, {})
                ).map(([name, quantity]) => (
                  <TableRow>
                    <TableCell>{name}</TableCell>
                    <TableCell>{quantity}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </div>
  );
};

export default ArmorSearch;
