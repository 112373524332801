import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ItemSearch from "./ItemSearch";
import ArmorSearch from "./ArmorSearch";
import reportWebVitals from "./reportWebVitals";
import { Global, css } from "@emotion/react";

ReactDOM.render(
  <React.StrictMode>
    <Global
      styles={css`
        body {
          background-color: #eee;
          margin: 20px;
          font-family: Roboto;
        }
      `}
    />
    {/*<ItemSearch />*/}
    <ArmorSearch />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
