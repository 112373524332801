import { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { css } from "@emotion/react";
const ranks = {
  LOW_RANK: "Low Rank",
  HIGH_RANK: "High Rank",
  G_RANK: "G Rank",
};

const itemDropStyles = css`
  width: 1000px;
  margin: 20px;
`;

const formStyles = css`
  display: flex;
  width: 500px;
`;

const selectStyles = css`
  padding-right: 10px;
  width: 100%;
`;

const searchButtonStyles = css`
  background-color: #fff;
  height: 30px;
  width: 120px;
  padding: 20px;
`;

function ItemSearch() {
  const [items, setItems] = useState([]);
  const [drops, setDrops] = useState({});

  useEffect(() => {
    async function fetchData() {
      const resp = await fetch('/items');
      let jsonBody = await resp.json();
      setItems(jsonBody);
    }

    fetchData();
  }, []);

  const fetchItemDrops = async (e) => {
    e.preventDefault();

    const dropsForItems = {};

    let monsterParts = document.getElementById("parts_form").parts;

    if(monsterParts.length) {
      for (let i = 0; i < monsterParts.length; i++) {
        const itemId = monsterParts[i].value;
        const drps = await fetch(`/itemDrops/${itemId}`);
        const drpsjson = await drps.json();
        dropsForItems[itemId] = drpsjson;
      }
    } else {
      const itemId = monsterParts.value;
      const drps = await fetch(`/itemDrops/${itemId}`);
      const drpsjson = await drps.json();
      dropsForItems[itemId] = drpsjson;
    }

    setDrops(dropsForItems);
  };
  return (
    <div>
      <Typography variant="h4">What you need?</Typography>
      <form css={formStyles} id="parts_form">
        <Select
          css={selectStyles}
          name="parts"
          isMulti
          options={items.map(({ id, name }) => ({ label: name, value: id }))}
        />

        <Button css={searchButtonStyles} onClick={fetchItemDrops}>
          <SearchIcon />
          Search
        </Button>
      </form>

      {Object.entries(drops).length > 0 &&
        Object.entries(drops).map(([key, dropsForItem]) => (
          <div css={itemDropStyles} key={key}>
            <Typography>
              {items.find(({ id }) => id === key).name}
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Monster name</TableCell>
                    <TableCell align="right">Rank</TableCell>
                    <TableCell align="right">What</TableCell>
                    <TableCell align="right">Where</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Drop rate</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dropsForItem.map(
                    ({
                      action,
                      bodyPart,
                      monster,
                      percentage,
                      quantity,
                      rank,
                    }) => (
                      <TableRow>
                        <TableCell>{monster.name}</TableCell>
                        <TableCell align="right">{ranks[rank]}</TableCell>
                        <TableCell align="right">{action}</TableCell>
                        <TableCell align="right">{bodyPart}</TableCell>
                        <TableCell align="right">{quantity}</TableCell>
                        <TableCell align="right">{percentage}%</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
    </div>
  );
}
export default ItemSearch;
